<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Komisi</label>
        <vs-input class="w-full" :value="initData.komisi.total_komisi | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Bayar</label>
        <vs-input class="w-full" :value="initData.komisi.total_bayar | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Sisa</label>
        <vs-input class="w-full" :value="initData.komisi.total_sisa | idr" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Persen Komisi</label>
        <vs-input class="w-full" :value="initData.komisi.persen_komisi + '%'" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Harga Dasar Komisi</label>
        <vs-input class="w-full" :value="initData.komisi.harga_dasar_komisi | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Closing Fee</label>
        <vs-input class="w-full" :value="initData.komisi.closing_fee | idr" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PPh Jenis</label>
        <vs-input class="w-full" :value="initData.komisi.pph_nama" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PPh</label>
        <vs-input class="w-full" :value="idr(initData.komisi.pph_jml) + ' (' + initData.komisi.pph_persen + '%)'" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Status NPWP</label>
        <vs-input class="w-full" :value="initData.komisi.status_npwp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.komisi.keterangan" readonly/>
      </div>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabKomisi',
  props: {
    initData: {
      default: null,
      type: Object
    }
  },
  data () {
    return {}
  },
  methods: {
    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.komisiApproval.activeTab
      this.$store.commit('approvals/komisiApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.komisiApproval.activeTab
      this.$store.commit('approvals/komisiApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
